export const fetchData = async (url: string, options = {}) => {
  const token = localStorage.getItem('token');
  try {
    const response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      ...options, // Permite sobrescribir el método y el body para POST
    });

    if (response.ok) {
      return await response.json();
    } else {
      console.error('Failed to fetch data:', response.statusText);
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};
