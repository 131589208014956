import { useNavigate } from 'react-router-dom';

export const Sales: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const handleNav = () => {
    navigate('/declaration');
  };

  return (
    <button
      className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white font-semibold py-3 px-6 rounded-lg shadow-lg hover:shadow-xl hover:from-indigo-600 hover:to-purple-500 transition-all duration-300 ease-in-out transform hover:scale-105 backdrop-filter backdrop-blur-lg bg-opacity-30 border border-white border-opacity-20"
      onClick={handleNav}
    >
      Generar Declaración de Venta
    </button>
  );
};
