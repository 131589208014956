// components/Orders/OrderDetail.tsx

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../../api';
import { fetchData } from '../utils';

interface OrderDetail {
  id: number;
  status: string;
  currency: string;
  total: number;
  date_created: string;
  date_modified: string;
  discount_total: number;
  shipping_total: number;
  total_tax: number;
  billing_first_name: string;
  billing_last_name: string;
  billing_address_1: string;
  billing_address_2: string | null;
  billing_city: string;
  billing_state: string;
  billing_postcode: string | null;
  billing_country: string;
  billing_email: string;
  billing_phone: string;
  items: OrderItem[];
}

interface OrderItem {
  id: number;
  detail_id: string;
  quantity: number;
  subtotal: number;
  price: number;
  status: string;
}

const OrderDetail: React.FC = () => {
  const { order_id } = useParams<{ order_id: string }>();
  const [orderDetail, setOrderDetail] = useState<OrderDetail | null>(null);
  const [imageUrl, setCurrentImageUrl] = useState<string>('');
  const [open, setIsImageModalOpen] = useState<boolean>(false);
  const token = localStorage.getItem('token');

  const fetchOrderDetail = async () => {
    try {
      if (!order_id) return;
      const response = await fetch(api.order(order_id), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token ? `Bearer ${token}` : '',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setOrderDetail(data);
        console.log(data, '****', order_id, data.items);
      } else {
        console.error('Failed to fetch order detail:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching order detail:', error);
    }
  };

  useEffect(() => {
    fetchOrderDetail();
  }, [order_id]);

  const getImageUrl = (detailId: string) => {
    const [code, number] = detailId.split('-');
    return `https://stickerseller-images-2.s3.amazonaws.com/${code}/${number}.JPG`;
  };

  const handleImageClick = (imageUrl: string) => {
    setCurrentImageUrl(imageUrl);
    setIsImageModalOpen(true);
  };

  if (!orderDetail) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">
        Detalle de Orden #{orderDetail.id}
      </h1>
      <div className="bg-white shadow rounded-lg p-6 mb-4">
        <p>
          <strong>Estado:</strong> {orderDetail.status}
        </p>
        <p>
          <strong>Total:</strong> {orderDetail.currency} {orderDetail.total}
        </p>
        <p>
          <strong>Descuento Total:</strong> {orderDetail.currency}{' '}
          {orderDetail.discount_total}
        </p>
        <p>
          <strong>Envío Total:</strong> {orderDetail.currency}{' '}
          {orderDetail.shipping_total}
        </p>
        <p>
          <strong>Impuesto Total:</strong> {orderDetail.currency}{' '}
          {orderDetail.total_tax}
        </p>
        <p>
          <strong>Creado el:</strong> {orderDetail.date_created}
        </p>
        <p>
          <strong>Última actualización:</strong> {orderDetail.date_modified}
        </p>
        <h3 className="text-xl font-semibold mt-4">Detalles de Facturación</h3>
        <p>
          <strong>Nombre:</strong> {orderDetail.billing_first_name}{' '}
          {orderDetail.billing_last_name}
        </p>
        <p>
          <strong>Dirección:</strong> {orderDetail.billing_address_1},{' '}
          {orderDetail.billing_address_2
            ? `${orderDetail.billing_address_2},`
            : ''}{' '}
          {orderDetail.billing_city}, {orderDetail.billing_state},{' '}
          {orderDetail.billing_country} {orderDetail.billing_postcode}
        </p>
        <p>
          <strong>Email:</strong> {orderDetail.billing_email}
        </p>
        <p>
          <strong>Teléfono:</strong> {orderDetail.billing_phone}
        </p>
      </div>

      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Detalle de los Productos</h2>
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="bg-gray-200 text-left">
              <th className="py-2 px-4">ID</th>
              <th className="py-2 px-4">Producto</th>
              <th className="py-2 px-4">Cantidad</th>
              <th className="py-2 px-4">Subtotal</th>
              <th className="py-2 px-4">Precio</th>
              <th className="py-2 px-4">Estado</th>
              <th className="py-2 px-4">Foto</th>
            </tr>
          </thead>
          <tbody>
            {orderDetail.items.map((item) => (
              <tr key={item.id} className="border-t border-gray-200">
                <td className="py-2 px-4">{item.id}</td>
                <td className="py-2 px-4">{item.detail_id}</td>
                <td className="py-2 px-4">{item.quantity}</td>
                <td className="py-2 px-4">
                  {orderDetail.currency} {item.subtotal}
                </td>
                <td className="py-2 px-4">
                  {orderDetail.currency} {item.price}
                </td>
                <td className="py-2 px-4">{item.status}</td>
                <td className="py-2 px-4">
                  <img
                    src={getImageUrl(item.detail_id)}
                    alt="Extracted Item"
                    className="h-16 w-16 object-cover rounded-md cursor-pointer"
                    onClick={() =>
                      handleImageClick(getImageUrl(item.detail_id))
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderDetail;
