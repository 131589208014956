import React, { useEffect, useState } from 'react';
import {
  ColumnDef,
  PaginationState,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from '@tanstack/react-table';
import { api } from '../api';
import { fetchData } from '../Orders/utils';

interface OrderItem {
  id: number;
  name: string;
  quantity: number;
}

interface OrderDetail {
  id: number;
  detail_id: number;
  status: string;
  currency: string;
  total: number;
  date_created: string;
  date_modified: string;
  discount_total: number;
  shipping_total: number;
  total_tax: number;
  billing_first_name: string;
  billing_last_name: string;
  billing_address_1: string;
  billing_address_2: string | null;
  billing_city: string;
  billing_state: string;
  billing_postcode: string | null;
  billing_country: string;
  billing_email: string;
  billing_phone: string;
  items: OrderItem[];
}

export const Declaration: React.FC = () => {
  const [orders, setOrders] = useState<OrderDetail[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<
    { detail_id: number; quantity: number }[]
  >([]);
  const [error, setError] = useState<string | null>(null);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const token = localStorage.getItem('token');

  const fetchOrders = async () => {
    const data = await fetchData(api.orderByStatus('En inventario'));
    if (data) {
      setOrders(data);
    } else {
      setError('Error al obtener las órdenes');
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const handleCheckboxChange = (detail_id: number, quantity: number) => {
    setSelectedOrders((prevSelected) => {
      const isSelected = prevSelected.some(
        (order) => order.detail_id === detail_id
      );
      if (isSelected) {
        return prevSelected.filter((order) => order.detail_id !== detail_id);
      } else {
        return [...prevSelected, { detail_id, quantity }];
      }
    });
  };

  const calculateQuantity = (items: OrderItem[] | undefined): number => {
    return items ? items.reduce((sum, item) => sum + item.quantity, 0) : 0;
  };

  const columns: ColumnDef<OrderDetail>[] = [
    {
      accessorKey: 'detail_id',
      header: () => 'ID',
    },
    {
      accessorKey: 'order_id',
      header: () => 'Orden ID',
    },
    {
      accessorKey: 'quantity',
      header: () => 'Cantidad',
      cell: ({ row }) => {
        const quantity = calculateQuantity(row.original.items);
        return quantity;
      },
    },
    {
      accessorKey: 'status',
      header: () => 'Estado',
    },
    {
      id: 'checkbox',
      header: () => 'Seleccionar',
      cell: ({ row }) => {
        const quantity = calculateQuantity(row.original.items); // Calculamos el quantity de forma segura
        return (
          <input
            type="checkbox"
            checked={selectedOrders.some(
              (order) => order.detail_id === row.original.detail_id
            )}
            onChange={() =>
              handleCheckboxChange(
                row.original.detail_id,
                quantity // Pasamos el quantity calculado correctamente
              )
            }
            className="form-checkbox h-5 w-5 text-blue-500"
          />
        );
      },
    },
  ];

  const table = useReactTable({
    columns,
    data: orders,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
  });

  console.log(selectedOrders, 'ordenes seleccionadas');
  const handleDeclaration = async () => {
    try {
      // Transforma selectedOrders en el formato requerido como un solo objeto
      const transformedDetails = selectedOrders.reduce(
        (acc, order) => {
          acc[order.detail_id] = order.quantity;
          return acc;
        },
        {} as Record<string, number>
      );

      // Convierte transformedDetails a una cadena JSON para enviarlo como 'detail'
      const detailString = JSON.stringify(transformedDetails);

      const response = await fetch(api.declarationProcess, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          detail: detailString, // Enviamos 'detail' como una cadena JSON
        }),
      });

      if (response.ok) {
        const result = await response.json();
        alert('Declaración enviada con éxito');
        console.log(result); // Muestra la declaración enviada
      } else {
        setError('Error al enviar la declaración');
        console.error('Error:', response.statusText);
      }
    } catch (error) {
      setError('Error al enviar la declaración');
      console.error('Error:', error);
    }
  };

  console.log(orders);

  return (
    <div className="overflow-x-auto max-w-4xl mx-auto mt-10">
      <h5 className="mb-5 text-4xl font-bold text-center text-blue-600">
        Declaración de Venta
      </h5>

      <table className="min-w-full bg-white rounded-lg shadow-md overflow-hidden">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="bg-gradient-to-r from-blue-500 to-purple-600 text-white"
            >
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {orders.length > 0 ? (
            table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="hover:bg-blue-50 transition-colors">
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-700 border-b border-gray-200 text-center"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={columns.length}
                className="text-center py-6 text-gray-500"
              >
                No existen órdenes en tu inventario
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Controles de Paginación */}
      <div className="flex justify-between items-center mt-6 space-x-2">
        <div className="flex space-x-1">
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            className="px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
          >
            {'<<'}
          </button>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
          >
            {'<'}
          </button>
        </div>

        <span className="text-sm font-semibold text-gray-700">
          Página {pagination.pageIndex + 1} de {table.getPageCount()}
        </span>

        <div className="flex space-x-1">
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
          >
            {'>'}
          </button>
          <button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
            className="px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
          >
            {'>>'}
          </button>
        </div>

        <select
          value={pagination.pageSize}
          onChange={(e) => table.setPageSize(Number(e.target.value))}
          className="ml-4 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {[10, 20, 30, 40, 50].map((size) => (
            <option key={size} value={size}>
              Mostrar {size}
            </option>
          ))}
        </select>
      </div>

      <button
        className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white font-semibold py-3 px-6 rounded-lg shadow-lg hover:shadow-xl hover:from-indigo-600 hover:to-purple-500 transition-all duration-300 ease-in-out  backdrop-filter backdrop-blur-lg bg-opacity-30 border border-white border-opacity-20 mt-5"
        onClick={handleDeclaration}
      >
        Declarar
      </button>
    </div>
  );
};
