import React from 'react';
import { Link } from 'react-router-dom';

interface SidebarProps {
  isAuthenticated: boolean;
  userRole: string;
}

const Sidebar: React.FC<SidebarProps> = ({ isAuthenticated, userRole }) => {
  return (
    <div className="flex flex-col h-screen w-64 bg-gray-800 text-white">
      <ul className="mt-40">
        {' '}
        {/* Añade margen superior aquí */}
        <li>
          <Link to="/" className="px-4 py-2 block text-white hover:bg-gray-700">
            Home
          </Link>
        </li>
        {/* Condiciones basadas en el rol del usuario */}
        {isAuthenticated && userRole === 'admin' && (
          <>
            <li>
              <Link
                to="/orders"
                className="px-4 py-2 block text-white hover:bg-gray-700"
              >
                Ordenes
              </Link>
            </li>
            <li>
              <Link
                to="/work_orders"
                className="px-4 py-2 block text-white hover:bg-gray-700"
              >
                Órdenes de Trabajo
              </Link>
            </li>
          </>
        )}
        {/* El usuario seller no ve 'Ventas' */}
        {isAuthenticated && userRole === 'seller' && (
          <>
            <li>
              <Link
                to="/orders"
                className="px-4 py-2 block text-white hover:bg-gray-700"
              >
                Ordenes
              </Link>
            </li>
            <li>
              <Link
                to="/inventory"
                className="px-4 py-2 block text-white hover:bg-gray-700"
              >
                Inventario
              </Link>
            </li>
            <li>
              <Link
                to="/sales"
                className="px-4 py-2 block text-white hover:bg-gray-700"
              >
                Ventas
              </Link>
            </li>
            <li>
              <Link
                to="/shops"
                className="px-4 py-2 block text-white hover:bg-gray-700"
              >
                Tiendas
              </Link>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
