import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import ImageModal from './imageModal'; // Importa correctamente
import Modal from './modals'; // Importa correctamentes
import { api } from '../../api';

interface WorkOrder {
  id: number;
  status: string;
  created_at: string;
  updated_at: string;
}

interface Extracted {
  id: number;
  status: string;
  collection_detail_id: string;
  detail_id: string;
  created_at: string;
  updated_at: string;
  order_id: number;
}

const WorkOrderDetail: React.FC = () => {
  const { work_order_id } = useParams<{ work_order_id: string }>();
  const [workOrder, setWorkOrder] = useState<WorkOrder | null>(null);
  const [extractedItems, setExtractedItems] = useState<Extracted[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState<string | null>(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const fetchWorkOrderDetails = useCallback(async () => {
    if (!work_order_id) return;

    const response = await fetch(api.workOrder(work_order_id));
    if (response.ok) {
      const data = await response.json();
      setWorkOrder(data.work_order);
      setExtractedItems(data.extracted_items);
      console.log(data);
    }
  }, [work_order_id]);

  useEffect(() => {
    fetchWorkOrderDetails();
  }, [fetchWorkOrderDetails]);

  const handleCheckboxChange = (itemId: number) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(itemId)
        ? prevSelected.filter((id) => id !== itemId)
        : [...prevSelected, itemId]
    );
  };

  const handleImageClick = (imageUrl: string) => {
    setCurrentImageUrl(imageUrl);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
    setCurrentImageUrl(null);
  };

  const openConfirmModal = () => setIsConfirmModalOpen(true);
  const closeConfirmModal = () => setIsConfirmModalOpen(false);

  const handleFinishProcessing = async () => {
    setIsProcessing(true);
    console.log(selectedItems);

    const response = await fetch(
      'https://backend.stickerseller.cl/api/update_extractions',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          extracted_ids: selectedItems,
          work_order_id: work_order_id,
        }),
      }
    );

    if (response.ok) {
      const data = await response.json();
      console.log('Procesamiento completado:', data);
      setSelectedItems([]);
      closeConfirmModal();
      setSelectedItems([]);
      fetchWorkOrderDetails();
    } else {
      console.error('Falló el procesamiento:', response.statusText);
    }
    setIsProcessing(false);
  };

  const getImageUrl = (detailId: string) => {
    const [code, number] = detailId.split('-');
    return `https://stickerseller-images-2.s3.amazonaws.com/${code}/${number}.JPG`;
  };

  if (!workOrder) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">
        Detalle de Orden de Trabajo #{workOrder.id}
      </h1>
      <p>
        <strong>Estado:</strong> {workOrder.status}
      </p>
      <p>
        <strong>Creado el:</strong> {workOrder.created_at}
      </p>
      <p>
        <strong>Última actualización:</strong> {workOrder.updated_at}
      </p>

      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Elementos Extraídos</h2>
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="bg-gray-200 text-left">
              <th className="py-2 px-4">ID</th>
              <th className="py-2 px-4">WOOCOMMERCE ID</th>
              <th className="py-2 px-4">Detalle ID</th>
              <th className="py-2 px-4">ID de Colección</th>
              <th className="py-2 px-4">Estado</th>
              <th className="py-2 px-4">Foto</th>
              <th className="py-2 px-4">Seleccionar</th>
            </tr>
          </thead>
          <tbody>
            {extractedItems.map((item) => (
              <tr key={item.id} className="border-t border-gray-200">
                <td className="py-2 px-4">{item.id}</td>
                <td className="py-2 px-4">{item.order_id}</td>
                <td className="py-2 px-4">{item.detail_id}</td>
                <td className="py-2 px-4">{item.collection_detail_id}</td>
                <td className="py-2 px-4">{item.status}</td>
                <td className="py-2 px-4">
                  <img
                    src={getImageUrl(item.detail_id)}
                    alt="Extracted Item"
                    className="h-16 w-16 object-cover rounded-md cursor-pointer"
                    onClick={() =>
                      handleImageClick(getImageUrl(item.detail_id))
                    }
                  />
                </td>
                <td className="py-2 px-4">
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id)}
                    disabled={item.status === 'Procesado'} // Deshabilitar checkbox si el estado es 'Procesado'
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedItems.length > 0 && (
        <button
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          onClick={openConfirmModal}
        >
          Terminar Proceso
        </button>
      )}

      <Modal
        isOpen={isConfirmModalOpen}
        onClose={closeConfirmModal}
        onConfirm={handleFinishProcessing}
        title="Confirmar Acción"
      >
        <p>
          ¿Estás seguro de que deseas terminar de procesar los elementos
          seleccionados?
        </p>
        {isProcessing && <div className="loading">Procesando...</div>}
      </Modal>

      <ImageModal
        isOpen={isImageModalOpen}
        onClose={closeImageModal}
        imageUrl={currentImageUrl || ''}
      />
    </div>
  );
};
export default WorkOrderDetail;
