const BASE_URL = 'https://backend.stickerseller.cl';

//backend.stickerseller.cl
//127.0.0.1:5000

export const api = {
  login: `${BASE_URL}/api/login`,
  signup: `${BASE_URL}/api/register`,
  workOrder: (workOrderId: string) =>
    `${BASE_URL}/api/work_orders?id=${workOrderId}`,
  orders: `${BASE_URL}/api/orders`,
  ordersByUser: `${BASE_URL}/api/orders_by_user`,
  order: (orderId: string) => `${BASE_URL}/api/orders?id=${orderId}`,
  orderByStatus: (status: string) => `${BASE_URL}/api/orders?status=${status}`,
  orderDetail: `${BASE_URL}/api/all_orderdetails`,
  declarationProcess: `${BASE_URL}/api/sale_declarations`,
};
