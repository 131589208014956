import React, { useEffect, useState } from 'react';
import {
  ColumnDef,
  PaginationState,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from '@tanstack/react-table';
import { api } from '../../../api';
import { fetchData } from '../../utils';
import { Order } from '../../types';

export const Inventory: React.FC = () => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const fetchOrders = async () => {
    const data = await fetchData(api.orderDetail); // Llama a `fetchData` con la URL de las órdenes
    if (data) {
      setOrders(data);
    } else {
      setError('Error al obtener las órdenes');
    }
  };

  useEffect(() => {
    fetchOrders(); // Llama a la función `fetchOrders` cuando el componente se monta
  }, []);

  // Definición de columnas para la tabla
  const columns: ColumnDef<Order>[] = [
    {
      accessorKey: 'id',
      header: () => 'ID',
    },
    {
      accessorKey: 'status',
      header: () => 'Estado',
    },
    {
      accessorKey: 'created_at',
      header: () => 'Creado En',
    },
    {
      accessorKey: 'updated_at',
      header: () => 'Actualizado En',
    },
    {
      accessorKey: 'items',
      header: () => 'Items',
      cell: ({ row }) => (
        <ul>
          {row.original.items?.map((item) => (
            <li
              key={item.id}
            >{`Item ID: ${item.id} - Estado: ${item.status}`}</li>
          ))}
        </ul>
      ),
    },
  ];

  const table = useReactTable({
    columns,
    data: orders,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
  });

  return (
    <div className="overflow-x-auto max-w-4xl mx-auto mt-10">
      <h5 className="mb-5 text-4xl font-bold text-center text-blue-600">
        Inventario de Productos
      </h5>

      <table className="min-w-full bg-white rounded-lg shadow-md overflow-hidden">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="bg-gradient-to-r from-blue-500 to-purple-600 text-white"
            >
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {orders.length > 0 ? (
            table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="hover:bg-blue-50 transition-colors">
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-700 border-b border-gray-200 text-center"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={columns.length}
                className="text-center py-6 text-gray-500"
              >
                No existen órdenes en tu inventario
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Controles de Paginación */}
      <div className="flex justify-between items-center mt-6 space-x-2">
        <div className="flex space-x-1">
          <button
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
            className="px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
          >
            {'<<'}
          </button>
          <button
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            className="px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
          >
            {'<'}
          </button>
        </div>

        <span className="text-sm font-semibold text-gray-700">
          Página {pagination.pageIndex + 1} de {table.getPageCount()}
        </span>

        <div className="flex space-x-1">
          <button
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            className="px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
          >
            {'>'}
          </button>
          <button
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
            className="px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
          >
            {'>>'}
          </button>
        </div>

        <select
          value={pagination.pageSize}
          onChange={(e) => table.setPageSize(Number(e.target.value))}
          className="ml-4 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {[10, 20, 30, 40, 50].map((size) => (
            <option key={size} value={size}>
              Mostrar {size}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
