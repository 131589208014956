import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { api } from '../../api';

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Redirecciona a home si el token existe
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/');
    }
  }, [navigate]);

  const handleRegisterNavigate = () => {
    navigate('/signup'); // Asegúrate de que esta ruta coincide con la configuración de tus rutas
  };

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    console.log('algo algo');
    try {
      const response = await axios.post(
        api.login,
        {
          email,
          password,
        },
        config
      );
      console.log(response.data, 'algo algo');
      localStorage.setItem('token', response.data.access_token);
      localStorage.setItem('userRole', response.data.role);

      navigate('/');
    } catch (err: any) {
      setError(
        err.response.data?.error || 'Error desconocido al iniciar sesión'
      );
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="p-6 bg-white shadow-md rounded">
        <h2 className="text-lg font-semibold">Iniciar sesión</h2>
        {error && <p className="text-red-500">{error}</p>}
        <form onSubmit={handleLogin}>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Correo electrónico
          </label>
          <input
            type="email"
            id="email"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label
            htmlFor="password"
            className="block mt-4 text-sm font-medium text-gray-700"
          >
            Contraseña
          </label>
          <input
            type="password"
            id="password"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button
            type="submit"
            className="mt-6 px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700 focus:outline-none"
          >
            Iniciar sesión
          </button>
        </form>
        <p className="mt-4">
          ¿No tienes cuenta?{' '}
          <span
            className="cursor-pointer text-indigo-600 hover:text-indigo-800"
            onClick={handleRegisterNavigate}
          >
            Regístrate
          </span>
        </p>
      </div>
    </div>
  );
};

export default LoginForm;
